@mixin checklist-bootstrap {
  @include flex(column);
  border-bottom: 1px solid $line-gray;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiCircularProgress-root {
    padding: 8px;
    top: 24;
    color: white;
    right: 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    gap: 8px;

    &__item {
      margin: 8px 0;
      grid-column: span 1;
      @include flex(row, flex-start, center);
      &--success {
        --successColor: #{$primary-green};
      }

      &--error {
        --successColor: #{$primary-red};
      }

      &--unavailable {
        --successColor: #{$line-gray};
      }

      &--pending {
        background-color: #{$letter-gray};
        height: 4px;
        border-radius: 1rem;
        width: 16px;
        margin-left: 8px;
      }
      > span {
        @include flex(row, flex-start, center);
        color: var(--successColor);
        font-weight: bold;
        margin-right: 8px;
      }

      svg {
        width: 24px;
        height: 24px;
        margin: 0 8px;
      }
      // I don't like it, but let it stay for now.
      &--error > svg {
        transform: scale(0.8);
        margin-right: 14px;
      }
    }
  }
}
