@mixin details-bootstrap {
  @include flex(column, space-between, flex-start);
  flex: 1;
  gap: 24px;

  &--preview {
    > div {
      @include flex(column, flex-start, flex-start);
      gap: 6px;
      color: $letter-gray;

      b {
        font-weight: bold;
      }
    }
  }

  > h2 {
    @include flex(row, flex-start, center);
    width: 100%;
    span {
      @include flex(row, center, center);

      svg {
        width: 28px;
        height: 28px;
        transform: scale(0.7);
        margin-left: 16px;
      }
    }
  }

  .ickyc-form {
    width: 100%;
    margin-bottom: 0;
    flex: 1;
    &__actions {
      margin-top: 32px;
      padding: 0;
      @include flex(row, center, center);
      button {
        margin: 4px;
        width: 86px;
      }
    }
  }
  &__divider {
    font-weight: bold;
    width: 100%;
    font-size: px-to-rem(16px);
    margin: 16px 0;
  }
  .ickyc-input,
  .ickyc-country-dropdown__wrapper,
  .ickyc-select__wrapper,
  .ickyc-datepicker {
    @include flex(column, flex-start);
    > label {
      font-size: px-to-rem(13px);
    }
    margin: 8px 0;
    width: 100%;
  }
  &__composite {
    @include flex(row, space-between);
    .ickyc-input {
      width: 49%;
    }
    flex-wrap: wrap;
  }
}

@include below-mobile() {
  .identity-details {
    padding: 16px;

    h2 {
      font-size: px-to-rem(22px);
    }

    > div {
      font-size: px-to-rem(18px);
    }
  }
}
