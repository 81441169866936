@import "../tools/tools";
$border-radius: 10px;

@mixin form-modal() {
  background-color: white;
  @include z-index("modal", 10);
  max-height: 90%;
  max-width: 90%;
  position: relative;

  border: 1px solid $dark-gray;
  @include border-top-radius($border-radius);
  @include border-bottom-radius($border-radius);
  @include flex(column, space-between);

  @include below-mobile() {
    width: 100%;
    max-height: 100%;
    height: 100%;
    max-width: 100%;

    @include border-top-radius(0);
    @include border-bottom-radius(0);
  }
  &__header {
    width: 100%;
    @include flex(row, space-between, flex-start);
    box-sizing: border-box;
    @include border-top-radius(10px);
    color: $primary-blue;
    &:first-child {
      padding: 16px;
      box-shadow: 0 10px 5px -5px #eee;
      @include below-mobile {
        padding: 8px;
      }
    }

    &:last-child {
      padding: 1.4rem 1rem;
      background-color: $white-smoke;
    }
  }

  &__footer {
    @include flex(row, space-between, center);
    background-color: $white-smoke;
    padding: 16px 24px;

    @include border-bottom-radius(10px);

    .ickyc-button {
      padding: 0px 32px;
      &--primary {
        min-width: 120px;
      }
      &--link {
        padding: 0;
        margin: 0;
        text-decoration: none;
        // min-width: 40px;
      }
    }
    .MuiCircularProgress-root {
      padding: 10px;
      color: white;
    }
    &__spacer {
      flex: 1 1 auto;
    }
    @include below-mobile {
      padding: 8px;
    }
    .ickyc-toggle {
      color: $letter-gray;
      font-weight: bold;
    }
  }
  &__content {
    padding: 32px;
    height: 100%;
    overflow-y: auto;
    &--loading {
      @include disability-style();
    }
    @include below-mobile() {
      padding: 16px;
      > div {
        flex: 1;
      }
    }
    .ickyc-confirmation-message {
      @include flex(row, center, center);
      color: $letter-gray;
      flex-wrap: wrap;
    }
  }
  &__submissionError {
    width: 60%;
    margin: 16px auto;
    padding: 16px 32px;
    border: 1px solid $primary-red;
    border-radius: 1rem;
    color: $primary-red;
    @include below-tablet() {
      width: 100%;
    }
    li {
      color: inherit;
    }
  }
  &__warning {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8);
    @include flex(column, center, center);
    > div {
      border: 1px solid $line-gray;
      border-radius: 1rem;
      width: 90%;
      @include flex(column, center, center);
      background-color: white;
      padding: 32px;
      h2 {
        text-align: center;
        margin-bottom: 32px;
      }
      div {
        @include flex(row, center, center);
        button {
          width: 150px;
          margin: 4px;
        }
      }
      .MuiCircularProgress-root {
        padding: 4px;
        color: white;
        margin-left: 10px;
      }
    }
  }
  @include below-tablet {
    width: 100%;
  }
}

.ickyc-confirmation-modal {
  .ickyc-form-modal {
    &__content {
      @include flex(column, center);
    }
    width: 100%;
  }
}
