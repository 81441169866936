@import './utilities.scss';
@import '../tools/tools.scss';

//// Button mixin which sets common button properties.
////
//// @param {string} background-color
//// @param {string} color
@mixin button-bootstrap($background-color, $color, $vertical-padding: 0, $horizontal-padding: 0) {
  @include flex(row, center, center);
  padding: $vertical-padding $horizontal-padding;
  background-color: $background-color;
  color: $color;
  @include reset-fields(outline, border);
  font-family: inherit;
  cursor: pointer;
  transition: background-color 0.4s ease-out, color 0.4s ease-out;
}

@mixin button-hover($background-color, $color) {
  background-color: $background-color;
  color: $color;
  transition: background-color 0.4s ease-out, color 0.4s ease-out;
  cursor: pointer;
}

@mixin button-disabled($background-color, $color) {
  @include disablity-style($background-color, $color);
}
