@import "../tools/t-flex";
@import "../mixins/utilities";
@import "../global-styles/g-colors";
@import "../tools/t-disability";
@import "../mixins/asterix-bootstrap";
@import "../mixins/select-bootstrap";
@import "../mixins/loader-bootstrap";
@import "../mixins/questionmark-tooltip";

@mixin dropdown-bootstrap {
  @include flex(row, flex-start);
  > label {
    @include flex(row, flex-start, center);
    padding: 0.5rem;
    color: $letter-gray;
    font-weight: bold;
    @include questionmark-tooltip-bootstrap();
  }
  &__wrapper {
    @include flex(column, flex-start);
    position: relative;
    min-width: 200px;
    flex: 1;
  }
  > div {
    position: relative;
  }

  &--error > label {
    color: $primary-red;
  }
  &--error &__trigger {
    color: $primary-red;
    border: 1px solid $primary-red;
  }
  &--error {
    .ickyc-error-message {
      color: $primary-red;
      font-size: px-to-rem(14px);
    }
  }

  &--disabled {
    @include disability-style;
  }
  &--preview &__trigger {
    border: none;
    &__svg {
      visibility: hidden;
    }
    pointer-events: none;
    > div {
      background-color: inherit;
    }
  }
  &--required > label {
    position: relative;
    @include asterix;
  }
}
