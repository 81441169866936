@import '../tools/tools';

@mixin modal-warning() {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.8);
  @include flex(column, center, center);
  > div {
    border: 1px solid $line-gray;
    border-radius: 1rem;
    width: 90%;
    @include flex(column, center, center);
    background-color: white;
    padding: 32px;

    div {
      @include flex(row, center, center);
      button {
        width: 150px;
        margin: 4px;
      }
    }
    .MuiCircularProgress-root {
      padding: 4px;
      color: white;
      margin-left: 10px;
    }
  }

  &__message {
    text-align: center;
    margin-bottom: 32px;
  }
}
