/**
  Map `z-indices` contains z-index values for the main parts
  of the page.
  Mixin `z-index` sets z-index to the component where the
  mixin is included. If provided key does not exist,
  z-index value is to `auto`.
*/
$z-index-stack: ('side-menu', 'header', 'modal', 'side-menu-responsive', 'select', 'select-trigger');

$index-start: 1000;
$z-index-values: ();

@each $component in $z-index-stack {
  $z-index-values: map-merge(
    $z-index-values,
    (
      $component: $index-start,
    )
  );
  $index-start: $index-start + 100;
}

@mixin z-index($key, $offset: 0) {
  @if (map-has-key($z-index-values, $key)) {
    z-index: map-get($z-index-values, $key) + $offset;
  } @else {
    z-index: auto;
  }
}
