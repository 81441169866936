@import '../tools/tools';

@mixin tag-bootstrap {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 24px;
  background-color: $primary-green;
  font-weight: bold;

  div {
    font-size: px-to-rem(14px);
    white-space: nowrap;
    overflow: hidden;
    color: inherit;
    text-overflow: ellipsis;
    max-width: 180px;
  }
}
