/**
    Predefined set of font-size values for the h1, h2, h3 and p element.
    Calculated manually to the nearest values of design according to
    the typographic scale.
    In-depth explanation can be found here:
    http://spencermortensen.com/articles/typographic-scale/
*/

$scale-map: ('h1': 32px, 'h2': 24px, 'h3': 20px, 'p': 16px);

@function t-scale($key) {
    @if (map-has-key($scale-map, $key)) {
        @return  map-get($scale-map, $key);
    } @else {
        @error "Key is not one of the following elements: h1, h2, h3, or p.";
    }
}