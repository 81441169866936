@mixin loader-bootstrap {
  position: relative;
  > .ickyc-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    pointer-events: all;
    z-index: 100;
  }
}
