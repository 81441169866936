$laptop-large: 1440px;
$laptop: 1024px;
$tablet: 768px;
$mobile: 480px;
$laptop-medium: 1100px;

@mixin above-laptop-large() {
  @media (min-width: $laptop-large) {
    @content;
  }
}

@mixin below-and-laptop-large() {
  @media (max-width: $laptop-large) {
    @content;
  }
}

@mixin below-laptop-large() {
  @media (max-width: $laptop-large - 1) {
    @content;
  }
}

@mixin below-laptop-medium() {
  @media (max-width: $laptop-medium - 1) {
    @content;
  }
}

@mixin below-laptop() {
  @media (max-width: $laptop) {
    @content;
  }
}

@mixin below-tablet() {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin below-mobile() {
  @media (max-width: $mobile) {
    @content;
  }
}
