@import "../global-styles/g-vars.scss";
@import "../tools/tools";

$n-tb: 64px;
$ll-tb: 48px;
$l-tb: 32px;
$ml-tb: 16px;

$n-lr: 96px;
$ll-lr: 48px;
$l-lr: 32px;
$ml-lr: 16px;

@mixin page-bootstrap {
  @include loader-bootstrap;
  padding: $n-tb $n-lr;
  > .ickyc-loader {
    width: calc(100% - #{$n-lr * 2});
    height: calc(100% - #{$n-tb * 2});
  }
  border-radius: 20px 0 0 0;
  background-color: white;
  border-top: 1px solid $primary-blue;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  @include below-laptop-large() {
    padding: $ll-tb $ll-lr;
    > .ickyc-loader {
      width: calc(100% - #{$ll-lr * 2});
      height: calc(100% - #{$ll-tb * 2});
    }
  }
  @include below-laptop() {
    width: 100%;
    margin-left: 0;
    padding: $l-tb $l-lr;
    > .ickyc-loader {
      width: calc(100% - #{$l-lr * 2});
      height: calc(100% - #{$l-tb * 2});
    }

    border-top: none;
  }

  @include below-tablet {
    border-radius: 0;
  }
  @include below-mobile {
    padding: 16px 24px;
    padding: $ml-tb $ml-lr;
    > .ickyc-loader {
      width: calc(100% - #{$ml-lr * 2});
      height: calc(100% - #{$ml-tb * 2});
    }
  }
}
