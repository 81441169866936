@import '../../../../../styles/global-styles/global.scss';

.ickyc-usage-page {
  .ickyc-loading {
    margin-top: 32px;
  }

  &__services-table {
    width: 100%;
    padding-top: 24px;

    td {
      color: $letter-gray;

      + td {
        padding-left: 20px;
      }
    }

    th {
      text-align: start;
      & + th {
        padding-left: 20px;
      }
    }

    th:nth-child(2) {
      width: 70%;
    }
  }

  &__platform-table {
    width: 100%;

    .ickyc-table__header__row {
      padding: 16px 4px;
    }

    tr {
      min-width: 400px;
    }
    .ickyc-table__column {
      &__item {
        flex: 3 0 300px;
        text-align: start;
      }
      &__creditsUsed {
        max-width: 256px;
        flex: 1 0 100px;
        text-align: center;
      }
    }
  }

  &__no-services-message {
    text-align: center;
  }
}
