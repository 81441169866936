@import '../../../styles/tools/tools';

$sdt-widths: (
  'name': 216px,
  'type': 216px,
  'dateCreated': 216px,
);
@function getSDTWidth($key) {
  @return getWidth($sdt-widths, $key);
}

$sdt-complete-width: getTotalWidth($sdt-widths);
