@import '../tools/t-flex';
@import '../tools/t-breakpoints';
@import '../global-styles/g-colors';

@mixin history-record-boostrap {
  padding: 32px;
  background-color: white;
  border-radius: 16px;
  &__wrapper {
    background-color: inherit;
    padding: 0 16px 16px;
    width: 100%;
    .ickyc-loading {
      margin: 0 auto;
    }
  }
  &__message {
    color: $letter-gray;
    text-align: center;
  }
  &__content {
    @include flex(row, space-between, flex-start);
    &__left {
      flex: 1 1 40%;
      border: 1px solid $line-gray;
      border-radius: 16px;
      padding: 32px;
    }
    &__right {
      flex: 1 1 60%;
      padding: 32px 0 32px 32px;
    }
    &__full {
      width: 100%;
      padding: 0px 32px;
    }
  }
  @include below-laptop-large {
    padding: 16px;
    &__wrapper {
      padding: 0 8px 8px;
    }
    &__content {
      &__left {
        padding: 16px;
      }
      &__right {
        padding: 16px;
      }
    }
  }
}
