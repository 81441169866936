@mixin radio-input-bootstrap() {
  margin: 0;
  outline: none;
}

@mixin text-input-bootstrap() {
  border: 1px solid $border-color;
  border-radius: 10px;
  padding: 0.5rem;
  outline: none;
}
