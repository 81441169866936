@import '../global-styles/g-vars';
@import '../mixins/list-bootstrap';
@import '../mixins/loader-bootstrap';
@import '../mixins/utilities';
@import '../tools/tools';

@mixin trigger-styles() {
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
  color: $letter-gray;
  border: 1px solid $border-color;
  background-color: inherit;
  position: relative;
  background-color: white;
}
@mixin trigger-content-styles() {
  @include flex(row, flex-start);
  min-height: 36px;
  flex: 1;
  &__content {
    @include flex(row, flex-start, center);
    &--single {
      padding: 4px;
    }
    &--multiple {
      flex-wrap: wrap;
      .ickyc-tag-button {
        margin: 2px;
      }
    }
  }
}
@mixin content-styles() {
  @include loader-bootstrap();
  @include border-bottom-radius(16px);

  background-color: white;
  z-index: 20;
  border: 1px solid $border-color;
  width: 100%;
  display: block;
  position: absolute;
  top: 100%;
}

@mixin select-list() {
  ul {
    max-height: 240px;
    overflow: auto;
    @include list-bootstrap;
    border-radius: 10px;
    border: 1px solid $border-color;
    text-decoration: none;
    li {
      word-break: initial;
      padding: 0.5rem;
      min-height: 36px;
      cursor: pointer;
      position: relative;

      &:nth-of-type(even) {
        background-color: $white-smoke;
      }

      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
        color: $line-gray;
      }

      &.selected {
        background-color: $primary-green;
        position: relative;
        color: white;
        outline: 1px solid white;
        @include flex(row, space-between);
        svg {
          position: relative;
          right: 0;
        }
      }
      &.hidden {
        visibility: hidden;
        min-height: 0;
        height: 0;
        padding: 0;
      }
      &.highlighted,
      &.hovered {
        background-color: $overlay;
        color: $letter-gray;
        outline: 1px solid $line-gray;
      }
      &.hovered.static {
        background-color: $primary-blue;
      }
      &.bolded {
        font-weight: bold;
      }
      &:hover {
        background-color: $overlay;
        outline: 1px solid $line-gray;
      }
      &.static {
        color: white;
        background-color: $primary-green;

        &:focus,
        &:hover {
          background-color: $primary-blue;
        }
      }
    }
  }
}
