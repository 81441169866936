@mixin drag-and-drop-bootstrap {
  transition: background-color ease-in-out 0.1s;

  &--draggable {
    &:hover {
      cursor: grab;
    }
  }

  &--drop-allowed {
    background-color: rgba($primary-green, 0.3);
  }

  &--drop-forbiden {
    background-color: rgba($primary-red, 0.3);
    cursor: not-allowed;
  }
}
