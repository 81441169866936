@import "../global-styles/g-vars.scss";
@import "../tools/tools";

@mixin update-form-bootstrap {
  padding: 32px;
  @include flex(row, center, flex-start);
  flex-wrap: wrap;
  background-color: white;
  border-radius: 1rem;
  &__block {
    @include flex(column, space-evenly, flex-start);
    flex-wrap: wrap;
    width: 50%;
    max-width: 500px;
    > div {
      margin: 4px 0;
      > label {
        @include flex(row, flex-end);
        min-width: 150px;
      }
      > div {
        width: 100%;
      }
    }
    &--full {
      margin-top: 4px;
      width: 100%;
      > div {
        > label {
          @include flex(row, flex-end);
          min-width: 150px;
        }
        > div {
          flex: 5;
        }
      }
    }
  }
  @include below-laptop-large {
    width: 100%;
    &__block {
      > div {
        > div {
          min-width: 200px;
        }
      }
    }
  }
  @include below-laptop {
    width: 100%;
    &__block {
      > div {
        > div {
          min-width: 200px;
        }
      }
    }
    &--responsive {
      &__block {
        &--full {
          width: 70%;
          > div {
            > label {
              flex: 1;
            }
            > div {
              flex: 2;
            }
          }
        }
      }
    }
  }
  @include below-tablet {
    &--responsive {
      @include flex(column, flex-start, center);
      &__block {
        width: 80%;
        > div {
          @include flex(column, flex-start);
          > label {
            text-align: left;
            @include flex(row, flex-start);
          }
        }
        &--full {
          width: 80%;
          margin-top: 4px;
          > div {
            @include flex(column, flex-start);
            > label {
              width: 100%;
              text-align: left;
            }
            > div {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @include below-mobile {
    padding: 0;
    &--responsive {
      &__block {
        width: 100%;
        > div {
          > label {
            padding-left: 8px;
          }
        }
        &--full {
          width: 100%;
        }
      }
    }
  }
}
