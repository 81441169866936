/// Clips text from the component displaying three dots at the end.
@mixin clip-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/// Sets 'none' as value for passed selectors.
@mixin reset-fields($props...) {
  @each $prop in $props {
    #{$prop}: none;
  }
}

/// Sets disability style.
///
/// @param {string} [background-color = transparent]
/// @param {string} [color = white]
@mixin disablity-style($background-color: transparent, $color: white) {
  color: $color;
  background-color: $background-color;
  opacity: 0.9;
  cursor: not-allowed;
}
