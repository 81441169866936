$primary-blue: #2f6fbc;
$dark-blue: #2f40b4;
$reply-blue: #14489e;
$light-blue: #5182dc;
$error-notice-blue: #5170b6;
$selected-light-blue: #eff5ff;
$primary-green: #02ce94;
$primary-red: #ff5353;
$dark-red: #a85d5d;
$primary-orange: #ff7600;
$dark-orange: #dd7e1f;
$white-smoke: #f5f5f5;
$dark-gray: #afb3b7;
$light-gray: #ebeaea;
$light-gray-2: #aeaeae;
$letter-gray: #5e5e5e;
$line-gray: #acb0b5;
$dodger-blue: #0089ff;
$overlay: #d5dee9;
$placeholder: #a7a7a7;
$border-color: #5e5e5e;
$description-grey: #6b778c;

$color-white: #fff;
