@import '../../../styles/tools/tools.scss';

$margin: 48px;

.ickyc-widget-main {
  padding: $margin $margin 0 $margin;
  background: transparent linear-gradient(166deg, #5182dc 0%, #2f40b4 100%) 0% 0% no-repeat padding-box;
  height: 100%;
  overflow: auto;

  &__global-message {
    @include flex(column, center, center);
    width: 100%;
  }
  @include flex(column, space-between);
  header {
    width: 100%;
    position: relative;
    top: 0;
    svg {
      width: 200px;
      height: auto;
    }
    margin-bottom: 64px;
  }
  &__content {
    @include flex(column, center, flex-start);
    width: 500px;
    max-height: 85%;
    margin: 0 auto;
    flex: 1;
    &__tab {
      @include flex(row, flex-start);
      height: 48px;
      background-color: white;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;

      &__cell {
        width: 144px;
        @include flex(row, center, flex-start);
        height: 100%;
        padding-top: 10px;
        font-size: px-to-rem(16px);
        font-weight: bold;

        &--active {
          color: $primary-blue;
          border-bottom: 4px solid $primary-blue;
        }
      }
    }
    > h3 {
      color: white;
    }

    .ickyc-form {
      width: 100%;
      max-width: 600px;
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
      @include flex(column, space-evenly);
      &__fields {
        height: auto;
        min-height: 260px;
        padding: 32px;
        &--tfa {
          padding: 8px 32px;
        }
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border: 1px solid $line-gray;

        background-color: white;
        @include flex(column, space-around, center);
        width: 100%;
        &--rounded {
          border-top-left-radius: 1rem;
        }
        .ickyc-input {
          margin: 8px auto;
          > label {
            width: 40%;
            text-align: right;
          }
          > div {
            padding-right: 48px;
            flex: 1;
          }
        }
        > h4 {
          width: 60%;
          margin: 0;
          float: left;
          text-align: left;
        }
        &__forgot-password {
          color: $primary-green;
          font-weight: bold;
          cursor: pointer;
          padding-left: 16px;
          margin: 0 auto;
        }
        &__error {
          margin-top: 16px;
          color: $primary-red;
        }
      }
      &__actions {
        width: 100%;
        .ickyc-button--primary {
          position: relative;
          border-radius: 1rem;
          width: 100%;
        }
        .MuiCircularProgress-root {
          padding: 8px;
          position: absolute;
          top: 0;
          color: white;
          right: 32px;
        }
        &--double {
          @include flex(row, space-between, center);
          .ickyc-button--primary {
            position: relative;
            border-radius: 1rem;
            width: 40%;
            &:first-of-type {
              background-color: transparent;
              border: 1px solid $primary-green;
            }
            .MuiCircularProgress-root {
              right: 8px;
            }
          }
        }
      }
      &__message {
        background-color: white;
        @include flex(column, center);
        padding: 64px 96px;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border: 1px solid $line-gray;
        text-align: center;
        h2 {
          color: black;
          font-size: px-to-rem(22px);
        }
        p {
          font-size: px-to-rem(18px);
        }
      }
    }
    @include below-mobile {
      width: 100%;
      &__tab {
        width: 100%;
        &__cell {
          width: 50%;
        }
      }
      .ickyc-form {
        &__fields {
          padding: 0;
          @include flex(column, space-around, center);
          .ickyc-input-input {
            @include flex(column, space-around, flex-start);

            margin: 0 auto;
            padding: 16px 0;
            width: 90%;
            > span {
              width: 130px;
              text-align: left;
              margin-bottom: 8px;
              margin-left: 10px;
            }
            > div {
              width: 100%;
            }
            &--error {
              span {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
  footer {
    border-top: 1px solid white;
    @include flex(row, space-between, center);
    margin-top: 64px;
    p,
    b {
      padding: $margin/4 0;
      color: white;
      padding: $margin/4 0;
    }
    a {
      text-decoration: none;
      color: white;
    }
    b {
      color: white;
    }
    @include below-mobile {
      text-align: center;
      @include flex(column, space-between, center);
    }
  }
  .ickyc-loading {
    p {
      color: white;
    }
    margin: 0 auto;
  }
  .ickyc-basic-info-step {
    &__widget-container {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }
  }
}
