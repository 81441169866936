@import '../../../styles/tools/tools';
@import '../../../styles/global-styles/g-kyc-page';
$max-column-count: calc(100%-1px);
$block: 100px;
@function countWidth($n, $gap) {
  $result: $n * $block + ($n - 1) * $gap;

  @return $result;
}
.ickyc-home-screen {
  &__content {
    width: 100%;
    margin-top: 32px;
    display: grid;
    column-gap: 32px;
    row-gap: 32px;
    grid-template-columns: repeat(auto-fill, $block);
    grid-template-rows: repeat(auto-fill, $block);
    .ickyc-card {
      &--3x3 {
        grid-column: span 3; // / span;
        grid-row: span 3;
        height: countWidth(3, 32);
      }
    }
    @include below-mobile {
      column-gap: 8px;
      row-gap: 8px;
      .ickyc-card {
        &--3x3 {
          grid-column: span 3; // / span;
          grid-row: span 3;
          height: countWidth(3, 9);
        }
      }
    }
  }
  .ickyc-card-summary {
    > div {
      @include flex(column, space-around);
      height: 100%;
      > h2 {
        font-size: px-to-rem(24px);
        text-align: center;
        color: $letter-gray;
      }
    }
    &__buttons {
      width: 100%;
      button {
        width: 140px;
      }
      @include flex(row, space-around);
    }
    &__info {
      div {
        margin: auto;
        @include flex(row, space-evenly, center);
        margin: 0 auto;
      }
      &__text {
        font-size: px-to-rem(16px);
        color: $letter-gray;
        font-weight: bold;
        width: 50%;
        text-align: right;
        margin-right: 32px;
      }
      &__number {
        font-size: px-to-rem(48px);
        flex: 1;
        color: $primary-blue;
      }
    }
  }
  .ickyc-table {
    @include flex(column, space-between);
    height: 100%;
    width: 100%;
    .ickyc-table-navigation {
      margin: 0;
    }
    &__row {
      td {
        min-height: auto;
        height: 36px;
      }
    }
    &__column {
      &__name {
        max-width: 230px;
        overflow-x: hidden;
        overflow-y: clip;
        flex: 1;
        svg {
          min-width: 20px;
          margin-right: 4px;
          color: $primary-blue;
        }
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
