@import '../global-styles/g-vars.scss';
@import './g-colors';
@import '../tools/tools';
@import '../mixins/bootstraps';

.ickyc-page {
  @include page-bootstrap();

  // Page description
  &__intro {
    margin: 48px 0;
  }
  &__content {
    @include loader-bootstrap();
    padding: 0 120px;
    @include below-laptop-large() {
      padding: 0 32px;
    }

    @include below-laptop() {
      padding: 0 24px;
    }

    @include below-mobile {
      padding: 0 16px;
    }
    &--disabled {
      @include disability-style();
      opacity: 0.5;
    }
  }
  &__submit {
    @include flex(row, center, center);
    flex-wrap: wrap;
    padding: 32px;
    button {
      width: 160px;
      margin: 8px;
      &:last-of-type {
        position: relative;
      }
    }
    .MuiCircularProgress-root {
      padding: 8px;
      position: absolute;
      top: 24;
      color: white;
      right: 0;
    }
  }
  &__acceptance {
    @include flex(row, flex-start, center);
    margin-top: 24px;
  }

  @include below-laptop() {
    // Page description
    &__intro {
      margin: 48px 0 0;
    }
  }
}

.ickyc-margin {
  margin-left: 20px;
}
