$directions: ('left', 'right', 'top', 'bottom');
$bezierAnimation: cubic-bezier(0.25, 0.1, 0.25, 1);

@function isInvalid($map, $value) {
  @return index($map, $value) == null;
}

@mixin slideInAnimation($direction: left, $duration: 1s) {
  @if (isInvalid($directions, $direction)) {
    @error 'Invaliid value for `direction` property.';
  }

  @if ($direction== 'left') {
    animation: $duration $bezierAnimation 0s 1 slideInFromLeft;
  } @else if(($direction== 'right')) {
    animation: $duration $bezierAnimation 0s 1 slideInFromRight;
  } @else if(($direction== 'top')) {
    animation: $duration $bezierAnimation 0s 1 slideInFromTop;
  } @else if(($direction== 'bottom')) {
    animation: $duration $bezierAnimation 0s 1 slideInFromBottom;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-60%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 100%;
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(60%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 100%;
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
